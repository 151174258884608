import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import dayjs from 'dayjs';
import './App.css';

const Home = () => {
  const [vars, setVars] = useState({});
  const [links, setLinks] = useState([]);

  useEffect(() => {
    fetch('/data/data.json')
      .then((response) => response.json())
      .then((data) => {
        setVars(data);
      });

    fetch('/data/links.json')
      .then((response) => response.json())
      .then((linksData) => {
        setLinks(linksData.categories);
      });
  }, []);

  if (!vars.lastExcuseDate) {
    return <div>Loading...</div>;
  }

  const today = dayjs();
  const lastExcuseDate = dayjs(vars.lastExcuseDate);
  const initialReleaseDate = dayjs(vars.initialReleaseDate);
  const daysSince = today.diff(lastExcuseDate, 'day');
  const daysSinceInitialRelease = today.diff(initialReleaseDate, 'day');
  const hasPCFutbol8Released = vars.hasPCFutbol8Released;
  const lastExcuse = vars.lastExcuse;
  const totalExcuses = vars.totalExcuses;

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-slate-100">
      <header className="bg-slate-400 w-full py-6">
        <h1 className="text-white text-4xl text-center">Héctor Prats PC Futbol 8</h1>
      </header>
      <main className="flex flex-col items-center mt-10">
      <div className="bg-white shadow-lg rounded-lg p-1 text-center  max-w-md w-full lg:max-w-2xl">
        <div className="p-1 mb-8">
          <h2 className="text-2xl mb-4 bg-sky-500 text-white p-2 rounded">¿Ha publicado PC Futbol 8?</h2>
          <div className="text-6xl font-bold">{hasPCFutbol8Released ? 'Sí' : 'No'}</div>
        </div>

        <div className="p-1 mb-8">
          <h2 className="text-2xl mb-4 bg-sky-500 text-white p-2 rounded">Días consecutivos de ghosting</h2>
          <div className="text-6xl font-bold">{daysSince}</div>
        </div>

        <div className="p-1 mb-8">
          <h2 className="text-2xl mb-4 bg-sky-500 text-white p-2 rounded">Días de retraso</h2>
          <div className="text-6xl font-bold">{daysSinceInitialRelease}</div>
        </div>

        <div className="p-1 mb-8">
          <h2 className="text-2xl mb-4 bg-sky-500 text-white p-2 rounded">Excusas totales</h2>
          <div className="text-6xl font-bold">{totalExcuses}</div>
        </div>

        <div className="p-1 mb-8">
          <h2 className="text-2xl mb-4 bg-sky-500 text-white p-2 rounded">Última excusa ({lastExcuseDate.format('DD-MM-YYYY')})</h2>
          <blockquote className="text-2xl italic font-semibold text-gray-900">
            <p>"{lastExcuse}"</p>
          </blockquote>
        </div>

        <div className="p-1 mb-8">
          <h2 className="text-2xl mb-4 bg-sky-500 text-white p-2 rounded">Enlaces</h2>
          <div className="flex flex-col space-y-4">
          {links.map((category, index) => (
                <div key={index} className="flex flex-col space-y-2">
                  <h3 key={index} className="category-title text-xl text-gray-700 pt-6">{category.title}</h3>
                  {category.links.map((link, linkIndex) => (
                    <a key={linkIndex} href={link.url} className="text-xl pt-2 text-blue-600">{link.text}</a>
                  ))}
                </div>
           ))}
          </div>
        </div>
      </div>
      </main>
    </div>
  );
};

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-blue-500 to-purple-600">
      <div className="bg-white shadow-lg rounded-lg p-8 text-center max-w-md">
        <h1 className="text-6xl font-bold text-blue-600 mb-4">404</h1>
        <h2 className="text-2xl text-gray-800 font-semibold mb-4">
          Página no encontrada
        </h2>
        <p className="text-gray-600 mb-8">
          Lo sentimos, la página que estás buscando no existe o ha sido movida.
        </p>
        <a
          href="/"
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow"
        >
          Volver al inicio
        </a>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};


export default App;
